

import { InputNumber } from 'antd'
import { Sized, StyledContainer, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import React, { useEffect, useRef, useState } from 'react'
import "./styles.scss"


export type NumberBoxProps = Fielded<number> & ChangeTracked<number> & StyledContainer & Wide & Sized & Partial<{

    children: number

    min: number, 
    max: number,
    step: number,
    precision: number,

    noControls: boolean,

    prefix: React.ReactNode
}>

export const NumberBox = (clientprops: NumberBoxProps) => {


    const trackedOnChange = clientprops.onChange ? ((v: number | undefined) => {

        lastChange.current = v

        clientprops.onChange?.(v)

    }) : undefined

    console.log("here")
    
    const props = useFieldProps({ ...clientprops, onChange: trackedOnChange, delay: clientprops.delay ?? true })

    useReadonlyHelper(props)

    const {

        children,
        defaultValue,

        min,max,
        step,
        precision,
        noControls,

        innerStyle,innerClassName,

        placeholder, prefix,

        onChange = console.log,

        width, minWidth, size,
        
        readonly, disabled,


        ...rest

    } = props

    // marks whether field has been manually emptied.
    const emptied = useRef(false)

    // fallback to default unless manually emptied.
    const currentValue = children ?? (emptied.current ? undefined : defaultValue)

    const { pastMode, pastValue } = useChangeHelper(props)

    const latestValue = pastMode ? pastValue : currentValue

    const [key, changeKey] = useState(0)

    // we need to customise this to hinour the emptied flag.
    useResetHelper(props, {
        
        //  if field has been emptied uses a special value to override behaviour that hides reset btn on undefined. 
        value:  emptied.current ? null! : children,

        // reset empty flag to trigger reset.
        onReset: () => {
        
            emptied.current=false
            change(undefined!)
        
        }
    })


    // resyncs field with an external change   
    //eslint-disable-next-line
    useEffect(() => {

        if (latestValue !== lastChange.current) {

            lastChange.current = latestValue

            changeKey(s => ++s)
        }

    })

    const change = (v: number | string | null) => {
        
        let value = (typeof v === 'string' ? parseFloat(v) : v) as number | undefined

        emptied.current = value===null

        if (value == null || isNaN(value))
            value = min

        onChange(value)

    }

    // track the last value reported by the field, so that we can tell if we'rendering:
    // 1. an internal change (comes from the field) 
    // 2. an external change (e.g. a reset).
    const lastChange = useRef<number | undefined>(latestValue)

    const adaptedSize = size === 'normal' ? 'middle' : size

    props.debug && console.log({ previous: children, defaultValue, currentValue, latestValue, readonly })

    const boxstyle = { ...innerStyle, minWidth, width}

    return <Field name="numberbox" {...rest} >
        <InputNumber key={key} 
                disabled={disabled}
                prefix={prefix} className={innerClassName} style={boxstyle} 
                size={adaptedSize} placeholder={placeholder} 
                defaultValue={latestValue} 
                type='number' 
                min={min}
                max={max}
                step={step}
                controls={!noControls}
                precision={precision}
                readOnly={readonly}
                onChange={change} />
    </Field>
}