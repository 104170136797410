import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { utils } from 'apprise-frontend-core/utils/common'
import axios, { AxiosInstance } from "axios"
import MockAdapter from 'axios-mock-adapter/types'
import { createContext, useContext } from 'react'
import { ClientConfiguration, defaultClientConfiguration } from "./config"
import { Mockery, useMockery } from './mocks'


export type ClientState = {

  // identifies this instance of the application, as loaded from the backend.
  runtimeId: string

  persistentMode: boolean

  impl: AxiosInstance

  // internal configuration: merges default, client, and remote configuration.
  config: ClientConfiguration

  // stores mockery so that they can be activated only when all config assure we're not in production.
  mockeries: Mockery[]

  // mock framework and in-memory databases.
  mocks: {

    adapter: MockAdapter
    stores: Record<string, any>

  }

}


export const initialClientState: ClientState = {

  runtimeId: utils().mint(),
  persistentMode: true,
  impl: undefined!,
  mockeries: [],
  config: defaultClientConfiguration,
  mocks: {
    adapter: undefined!,
    stores: {}
  }

}

// state-holding context, serves default until call mgmt is active, if ever.
export const ClientContext = createContext<State<ClientState>>(fallbackStateOver(initialClientState))


// exposes config
export const useClientConfig = () => useContext(ClientContext).get().config


export const useClient = () => {

  const state = useContext(ClientContext)

  const mocks = useMockery()

  const self = {

    // creates and configures an axios instance, activates registered mockeries.

    init: (config: Partial<ClientConfiguration>) => {

      // initialises axios implements.
      state.set(s => {

        // merges client config with internal config.
        s.config = utils().merge(s.config, config ?? {})

        // stores axios instance.
        s.impl = axios.create(s.config.request)

      })

      // initialises whichever mockeries may be already installed.
      const mockeries = state.get().mockeries ?? []

      mockeries.forEach(mocks.initMockery)

    }

  }

  return self
}
