import { RecordPatch, RecordSlot } from '#record/model';
import { useAppSettings } from '#settings/settings';
import { Asset, AssetReference } from '#submission/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { Bytestream, newBytestreamId } from 'apprise-frontend-streams/model';
import { useTagCache } from 'apprise-frontend-tags/cache';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { newFromFile } from '../lib/apprise-frontend-streams/api';
import { photographsTypeCategory, photographType, photoQualityLevels } from './constants';

export type PhotographsPatch = RecordPatch<typeof photographType>

export type Photo = {

    type: TagReference,
    ref: AssetReference | Asset
}

export type Photographs = RecordSlot & {

    photos: Photo[]

}

// minimal skeleton for missing slots.
export const newPhotoSlot = (): Photographs => ({

    timestamp: undefined!,
    id: utils().mint('PH'),
    photos: [] as Photo[]

})

export const thumbnailWidth = 300

export const newPhotoBytestreamFrom = (file: File, id: string = newBytestreamId('photo'), thumbnailId?:string): Bytestream => {

    const stream = newFromFile(file)

    return {
        ...stream,

        id,

        properties: {

            ...stream.properties,

            processors: [{ img: true, derivatives: [{ id: thumbnailId, width: thumbnailWidth }]} ]

        }
    }
}

export const imageOf = async (image: Blob) => {

    const img = new Image();

    const src = URL.createObjectURL(image)

    await new Promise((res, rej) => {

        img.onload = () => {

            URL.revokeObjectURL(src)

            res(undefined)

        }

        img.onerror = res

        img.onerror = rej

    })

    return img

}

export const usePhotoQualityHelper = () => {

    const t = useT()

    const { minPhotoQuality, validatePhotoQuality} = useAppSettings()

    const qualityLevel = photoQualityLevels[minPhotoQuality]

    const tags = useTagCache()
        
    return (photo: Asset, type: TagReference) =>  
    
        validatePhotoQuality && ((photo.size < qualityLevel?.fsize * 1024) && ((photo.properties.height ?? 0) * (photo.properties.width ?? 0) < qualityLevel?.isize * 1024 *1024)) && t('valid.undersized_photo', { type: tags.id2name(type).of(photographsTypeCategory), baseline: qualityLevel.msg })
        
}