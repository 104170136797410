

import { useExtensions } from '#extension/state';
import { iotcnumberscheme } from '#iotc/constants';
import { GenericRecord } from '#record/model';
import { DetailContext } from '#submission/context';
import { VidExtension } from '#vid/ext';
import { useVID } from '#vid/model';
import { utils } from 'apprise-frontend-core/utils/common';
import { TenantReference } from 'apprise-frontend-iam/tenant/model';
import { ParseContext } from 'apprise-frontend-parse/model';
import { useContext } from 'react';



// fallbacks for dates that arrive as text from parser xlsx parser.
export const textDateFormats = ['dd/MM/yy','dd/MM/yyyy','dd-MM-yy','dd-MM-yyyy']

// contextual information required for parsing, typically a sumission.
export type SubmissionParseContext = Partial<{

  tenant: TenantReference

}>

// this parses the parts that are common to all patches, completing the partial work of a patch-specific parsers.
// it requires contextual info (tenant).

export const useGenericPatchParser = <T extends GenericRecord>(parser: (row: any, ctx: ParseContext<SubmissionParseContext> ) => Partial<T>) => {
  
    const vids = useVID()    
    
    const vidextension = useExtensions<VidExtension>().get()

    const { edited } = useContext(DetailContext) ?? {}

    return (row: any, ctx:ParseContext<SubmissionParseContext>) => ({
  
      ...parser(row, ctx),
  
      id: utils().mint('R'),

      uvi: row.uvi ? vids.stringify(iotcnumberscheme, vidextension.formatUvi(row.uvi)) : undefined!,

      tenant: ctx?.tenant ?? edited?.tenant,

      timestamp: undefined!,
  
      lifecycle: {
        state: 'uploaded',
        created: Date.now()
  
      }
  
    }) as any as T
  
  }
  
