import { RavConfig } from '#config';
import { SubmissionParseContext, textDateFormats, useGenericPatchParser } from '#record/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { DeepPartial, utils } from 'apprise-frontend-core/utils/common';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useParseUtils } from 'apprise-frontend-parse/utils';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { trxauthzType, trxAuthzTypeCategory } from './constants';
import { TrxAuthPatch } from './model';
import { TrxAuthzJsonSerialization } from './jsonserializer';
import { unknownTenantId } from 'apprise-frontend-iam/tenant/model';




export const useTrxAuthzParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser = useDefaultModelParser(useTrxAuthzRowParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, trxauthzType)

  return useDefaultParser({ modelparser, bookparser })


}


export const useTrxAuthzRowParser = () => {

  const t = useT()
  const parse = useParseUtils()

  const parser = (row: TrxAuthzJsonSerialization, ctx: SubmissionParseContext) => {

    const grantedBy = ctx?.tenant ?? unknownTenantId

    const patch: DeepPartial<TrxAuthPatch> = {

      patchedSlots: [trxauthzType],

      [trxauthzType]: {

        timestamp: undefined!,

        id: utils().mint('TRX'),

        authzs: {

          [grantedBy]: [{

            timestamp: undefined!,
            type: parse.code2id(row.type).of(trxAuthzTypeCategory),
            from: parse.date(row.from, textDateFormats).orIssue('error').about(t('trxauthz.from')).on(row.uvi ?? t('rec.no_identifier')),
            to: parse.date(row.to).orIssue('error').about(t('trxauthz.to')).on(row.uvi ?? t('rec.no_identifier')),
            revocation: ['yes', 'oui', 'true', 'vrai', 'reoke', 'révoquer'].some(s => s.toLowerCase() === row.revoke?.trim().toLowerCase())

          }]
        }

      }
    }

    return patch as TrxAuthPatch
  }

  return useGenericPatchParser(parser)

}