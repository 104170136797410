import { utils } from 'apprise-frontend-core/utils/common';
import { Lifecycled } from "apprise-frontend-core/utils/lifecycle";


/** 
 *  describes a byte stream, optionally associated with some domain object and in the scope of some tenant.
 *  
 *  as abstract as a Blob, but it's metadata rather than an object, it serialises to cross the divide with the backend, 
 *  and it is an extensible container of application properties.
 *  
 * */

export type StandardBytestreamProperties = Partial<{

    path: string
    processors: Processor[]

}> & Record<string, any>

export type Processor = Record<string, any>

export type Bytestream<P extends StandardBytestreamProperties = StandardBytestreamProperties> = Lifecycled<string> & {

    /** internal identifier, opaque to the domain. */
    id: string

    /** a name taken from the domain. */
    name: string

    /** the type of content, typically a Mime type. */
    type: string

    // identifies a domain object that 'owns' the stream (implies bound lifecycles).
    ref?: string

    // limits visibility.
    tenant?: string

    size: number

    // application-specific properties, typically opaque to the backend.
    properties: P

}

export type BytestreamRef = Bytestream['id']

export const newBytestreamId = (prefix?: string) => `BS-${prefix ? `${prefix}-` : ``}${utils().mint()}`

export const isBytestream = (stream: any): stream is Bytestream => !!stream && !!(stream as Bytestream).name && !!(stream as Bytestream).size && !!(stream as Bytestream).type  
