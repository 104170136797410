import { RavConfig } from '#config';
import { beneficialOwnerTag, detailsType, grtTag, loaTag, operatingCompanyTag, operatorTag, ownerTag, tonnageTag, totalVolumeTag, totalVolumeTonnesTag, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from '#details/constants';
import { Contact, DetailsPatch } from '#details/model';
import { imoscheme, ircsscheme, natnoscheme, regnoscheme, tuvischeme } from '#iotc/constants';
import { SubmissionParseContext, useGenericPatchParser } from '#record/parser';
import { DetailContext } from '#submission/context';
import { useVID } from '#vid/model';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useParseUtils } from 'apprise-frontend-parse/utils';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { useContext } from 'react';
import { DetailsJsonSerialization } from './jsonserializer';
import { OperatingCompany } from './model';




export const useDetailsParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser = useDefaultModelParser(useDetailsRowParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, detailsType)

  return useDefaultParser({ modelparser, bookparser })


}


export const useDetailsRowParser = () => {

  const t = useT()

  const vids = useVID()
  const parse = useParseUtils()

  // we do our best to construct patchrd from rowd.
  // where we don't manage, we force a value (undefined, empty array, empty objects, etc)
  // and rely on validation to catch the errors.

  const { edited } = useContext(DetailContext) ?? {}

  const parser = (row: DetailsJsonSerialization, ctx?: SubmissionParseContext) => {

    const identifiers: string[] = []

    const tenant = ctx?.tenant ?? edited?.tenant

    if (row.imo)
      identifiers.push(vids.stringify(imoscheme, row.imo))

    if (row.regno)
      identifiers.push(vids.stringify(regnoscheme, row.regno))

    if (row.natno)
      identifiers.push(vids.stringify(natnoscheme, row.natno))

    if (row.ircs)
      identifiers.push(vids.stringify(ircsscheme, row.ircs))

    if (row.tuvi)
      identifiers.push(vids.stringify(tuvischeme, row.tuvi))

    const specifications: Record<TagReference, any> = {}

    if (row.loa)
      specifications[loaTag] = parse.float(row.loa,2).orIssue('error').about("LOA").on(row.uvi ?? t('rec.no_identifier'))

    if (row.tonnage)
      specifications[tonnageTag] = parse.float(row.tonnage,2).orIssue('error').about("GT").on(row.uvi ?? t('rec.no_identifier'))

    if (row.grt)
      specifications[grtTag] = parse.float(row.grt,2).orIssue('error').about("GRT").on(row.uvi ?? t('rec.no_identifier'))


    if (row.volume)
      specifications[totalVolumeTag] = parse.float(row.volume,2).orIssue('error').about("CCMT").on(row.uvi ?? t('rec.no_identifier'))

    if (row.volumeTonnes)
      specifications[totalVolumeTonnesTag] = parse.float(row.volumeTonnes,2).orIssue('warning').about("CCM3").on(row.uvi ?? t('rec.no_identifier'))

    const contacts: Contact[] = []

    contacts.push({ type: ownerTag, id: utils().mint('C'), name: row.owner!, address: row.ownerAddress! })
    contacts.push({ type: operatorTag, id: utils().mint('C'), name: row.operator!, address: row.operatorAddress! })
    contacts.push({ type: beneficialOwnerTag, id: utils().mint('C'), name: row.beneficial!, address: row.beneficialAddress! })
    contacts.push({ type: operatingCompanyTag, id: utils().mint('C'), name: row.company!, address: row.companyAddress!, regno: row.companyRegno } as OperatingCompany)

    const rowgears = (Array.isArray(row.gear) ? row.gear : row.gear?.split(";") ?? []).map(g =>g.trim()).map(g => g ? (parse.code2id(g).of(vesselGearCategory) ?? g) : undefined!)

    const vesselType = parse.code2tag(row.type).of(vesselTypeCategory)

    const priorHistory = {
      name: row.previousName!,
      flags: row.previousFlag!,
      deletions: row.previousDeletion!
    } 

    const patch: Partial<DetailsPatch> = {

      patchedSlots: [detailsType],

      [detailsType]: {

        timestamp: undefined!,
        id: utils().mint('D'),
        state: 'inactive',
        flagstate: tenant,
        name: row.name!,
        identifiers,
        vesselType: row.type ? vesselType?.id ?? row.type : undefined!,
        vesselKind: parse.code2id(row.kind).of(vesselKindCategory) ?? row.kind,
        range: parse.code2id(row.range).of(vesselRangeCategory),
        gears: rowgears,
        port: { name: row.portname!, code:row.portcode, flag:row.portflag! },
        specifications,
        contacts,
        priorHistory: Object.values(priorHistory).some(v => v) ? priorHistory : undefined!

      }

    }

    return patch

  }

  return useGenericPatchParser(parser)

}