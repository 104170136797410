import { RecordPatch, RecordSlot } from '#record/model';
import { DeepPartial, utils } from 'apprise-frontend-core/utils/common';
import { TenantReference } from 'apprise-frontend-iam/tenant/model';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { trxauthzType } from './constants';

export type TrxAuthPatch = RecordPatch<typeof trxauthzType> 

export type TrxAuthzs = RecordSlot & {

    authzs: Record<TenantReference, TrxAuthz[]>

  
}

export type TrxAuthz = {

    timestamp: string
    type: TagReference
    from: string
    to: string

    revocation?: boolean
}


// minimal skeleton for missing slots.
export const newTrxAuthzSlot = () => {

    const partials : DeepPartial<TrxAuthzs> = {

        id: utils().mint('TRX'),
        authzs: {}

    } 

    return partials as TrxAuthzs
}