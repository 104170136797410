import { bowTag, portsideTag, starboardTag } from '#details/constants'
import { boatStream } from '#record/mockery'
import { Asset } from '#submission/model'
import { useVIDUtils, Vid } from '#vid/model'
import { useMocks } from 'apprise-frontend-core/client/mocks'
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle'
import addDays from 'date-fns/addDays'
import { Photographs } from './model'
import { utils } from 'apprise-frontend-core/utils/common'


export const usePhotographsMocks = () => {

    const mocks = useMocks()
    const lifecycle = useLifecycleMocks()
    const vids = useVIDUtils()

  const self = {

        store: () => mocks.getOrCreateStore<Photographs>('photograps', { id: m => m.id })

        ,

        mockSlot: (props: { current?: Photographs, timestamp: string, vid: Vid }): Photographs => {

            const nameId = utils().mint()

            const { current, timestamp, vid } = props

            const uvi =  vid ? vids.valueOf(vid) : nameId 

            const archiveId = `${uvi}/${utils().mint()}`

            const mockAsset = (name: string): Asset => {

                return {
                    id: boatStream,
                    name: `${name}.jpg`,
                    size: mocks.randomNumberBetween(500, 10000),
                    type: `image/jpeg`,
                    lifecycle: { ...lifecycle.pastLifecycle(), created: addDays(-mocks.randomNumberBetween(1, 10), Date.parse(timestamp)).getTime() },
                    properties: {
                        archive: archiveId,
                        path: `${uvi}/${name}.jpg`
                    }

                }
            }


            return {

                ...current,

                id: utils().mint('PH'),
                timestamp,

                photos: [
                    { type: starboardTag, ref: mockAsset('starboard') },
                    { type: portsideTag, ref: mockAsset('portside') },
                    { type: bowTag, ref: mockAsset('bow') }
                ]
            }

        }

    }

    return self
}