import { Multilang, useL } from 'apprise-frontend-core/intl/multilang';
import deepmerge from "deepmerge";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { FC, ReactNode } from 'react';
import { customAlphabet } from 'nanoid';


export const elementRole = 'apprise-role'
export const elementContainerRole = 'apprise-container'
export const elementProxyRole = 'apprise-proxy'


const shortidAlphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-';
const shortidLength = 10

const nanoid = customAlphabet(shortidAlphabet, shortidLength);

export type DeepPartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? DeepPartial<U>[] :
  T[P] extends object ? DeepPartial<T[P]> :
  T[P];
};


export type Optional<T> = T | undefined | null | false | ''

export type OneOrMore<T> = T | T[]


export const useUtils = () => {

  const l = useL()

  const self = {

    ...utils()

    ,


    join: (ts: Optional<string | Multilang>[], separator: string = ' ') =>

      self.purge(utils().arrayOf(ts)).map(t => (typeof t === 'string' ? t : l(t))?.trim()).join(separator)



  }

  return self
}

export const utils = () => {

  const self = {

    mint: (prefix?: string) => prefix ?  `${prefix}-${nanoid()}` : nanoid()

    ,

    compose: <T>(ts: T[]) => ts.reduce((acc, next) => ({ ...acc, ...next }), {} as T)

    ,

    clock: <T>(name: string, f: () => T, logicalTasks?: number | ((_:T) => number)) => {

      const t0 = performance.now()
      const result = f()
      const t1 = performance.now() - t0

      const tasks = typeof logicalTasks === 'number' ? logicalTasks : logicalTasks?.(result)

      console.log(`${name} took ${t1} ms. ${tasks ? `(${Math.round(tasks*1000/t1)}/sec.)`:''}`)

      return result;

    }

    ,

    asyncClock: async  <T>(name: string, f: () => Promise<T>, logicalTasks?: number | ((_:T) => number)) => {

      const t0 = performance.now()
      const result = await f()
      const t1 = performance.now() - t0

      const tasks = typeof logicalTasks === 'number' ? logicalTasks : logicalTasks?.(result)

      console.log(`${name} took ${t1} ms. ${tasks ? `(${Math.round(tasks*1000/t1)}/sec.)`:''}`)

      return result;

    }

    ,

    wait: <T>(ms: any) => (x?: T) => new Promise<T | undefined>(v => setTimeout(() => v(x), ms))

    ,

    waitNow: (ms: any) => new Promise<void>(v => setTimeout(() => v(), ms))

    ,

    purge: <T>(ts: Optional<Optional<T>[]>) => ts ? ts.filter(t => !!t) as T[] : []

    ,


    through: <T>(fn: (t: T) => any) => (a: T): Promise<T> => Promise.resolve(fn(a)).then(_ => a)

    ,

    merge: <T>(target: T={} as T, ...values: any): T => deepmerge.all([target, ...values], {

      arrayMerge: (_, destination) => destination

    }) as any as T

    ,

    group: <T>(values: T[] = []) => ({

      by: <S>(key: (_: T) => S, compare: (t1: S, t2: S) => number) => values.reduce((acc, next) => {

        const nextkey = key(next)

        if (!nextkey)
          return acc

        const match = acc.find(e => compare(e.key, nextkey) === 0)

        if (match) {
          match.group.push(next)
          return acc
        }

        return [{ key: nextkey, group: [next] }, ...acc]

      }, [] as { key: S, group: T[] }[]).sort((g1, g2) => compare(g1.key, g2.key))



    })
    ,

    split: <T>(values: T[]) => {
      const self = {

        in: (chunk: number) => Object.values(

          values.reduce((acc, next, i) =>

            ({ ...acc, [Math.floor(i / chunk)]: [...acc[Math.floor(i / chunk)] ?? [], next] })


            , {} as Record<number, T[]>))

        ,

        max: (maxchunk: number) => {
          let chunk = maxchunk

          Array.from({ length: maxchunk + 1 }).map((_, i) => i).filter(i => i > 1).reduce((a, c) => {
            const reminder = values.length % c

            if (a >= reminder) {
              chunk = c
              return reminder
            }
            return a
          }, maxchunk)
          return self.in(chunk)
        }
      }
      return self
    }

    ,

    deepequals: <T>(t1: T, t2: T) => {
      return isEqual(t1, t2)
    }

    ,

    deepclone: <T>(t: T) => {
      return cloneDeep(t) as T
    }

    ,

    dedup: <T>(ts: T[]=[]) => ts.filter((_, i) => ts.indexOf(_) === i)

    ,

    dedupBy: <T>(ts: T[]=[], by: (t:T) => any) => ts.filter((t1, i) => {
    
      const s1 = by(t1)
      
      return ts.findIndex(t2 => self.deepequals(s1,by(t2))) === i
    
    })

    ,

    index: <T>(values: T[] = []) => ({

      mappingBy: <S>(key: (_: T) => string | undefined, value: (_: T) => S) => {

        const out: { [_: string]: S } = {}

        for (let i = 0; i < values.length; i++) {

          const next = values[i]
          const nextkey = key(next)

          if (nextkey)
            out[nextkey] = value(next)

        }

        return out
      }
      ,

      by: (key: (_: T) => string | undefined) => self.index(values).mappingBy(key, t => t),

      byGroup: (key: (_: T) => string | undefined) => self.index(values).mappingByGroup(key, t => t),

      mappingByGroup: <S>(key: (_: T) => string | undefined, value: (_: T) => S) => {

        const out: { [_: string]: S[] } = {}

        for (let i = 0; i < values.length; i++) {

          const next = values[i]
          const nextkey = key(next)
          
          if (nextkey){
            const curr = out[nextkey]
            if (curr)
               curr.push(value(next))
            else
              out[nextkey] = [value(next)]
          }
        }

        return out
      }      

    })

    ,

    //  indexes first, then returns the values, effectively overridden deduping those with the same key, 
    indexAndDedup: <T>(values: T[]) => ({

      by: (key: (_: T) => any) => Object.values(self.index(values).by(key))

    })

    ,

    compareStringsOf: <T>(f: (_: T) => string | undefined) => (r1: T, r2: T) => (f(r1) ?? '')?.localeCompare(f(r2) ?? '')

    ,

    compareNumbersOf: <T>(f: (_: T) => number) => (n1: T, n2: T) => (f(n1) ?? -1) - (f(n2) ?? -1)

    ,


    compareDatesOf: <T>(f: (_: T) => string | number | undefined) => (d1: T, d2: T) => self.compareDates(f(d1), f(d2))

    ,

    compareDates: (d1: string | number | undefined, d2: string | number | undefined, nullFirst: boolean = true) => {

      const outcome = !d1 ? (d2 ? nullFirst ? -1 : 1 : 0) : !d2 ? (nullFirst ? 1 : -1) : new Date(d1).getTime() - new Date(d2).getTime()

      //console.log("compared",d1,d2,"outcome",outcome)

      return outcome;

    }

    ,

    arrayOf: <T>(vals: OneOrMore<T>) => Array.isArray(vals) ? vals : vals !== undefined ? [vals] : []

    ,

    waitOn: (...all: Promise<any>[]) => Promise.allSettled(all).then(self.throwFirst)

    ,

    throwFirst: (all: PromiseSettledResult<any>[]) => all.filter((a): a is PromiseRejectedResult => a.status === 'rejected').forEach(r => { throw Error(r.reason) })

    ,

    elementsIn: (node: React.ReactNode): JSX.Element[] =>

      self.arrayOf(node)
        // flatten arrays
        .flatMap(c => Array.isArray(c) ? c : [c])
        // flatten fragments and containers (recur on children)
        .flatMap(c =>

          self.isElementOf(React.Fragment)(c) || self.hasRole(elementContainerRole)(c) ? self.elementsIn(c.props?.children) : [c]

        )
        // flatten proxied elements (recur on proxied)
        .flatMap(c => self.hasRole(elementProxyRole)(c) ? self.elementsIn(c.type(c.props)) : [c])
        // remove empties
        .filter(f => !!f)
        // keep typed elements
        .filter(c => c.type)

    ,

    /*
    *  encapsulates a runtime check on a react node to verify it's an element of a functional component.
    *  uses type names in a dev build as a stable identity, and the type identity in production build (where names are mangled).
    *  it's curried for convenience of use in iterations:  
    *  array.filter(isElementOf(MyComponent)) 
    */
    isElementOf: (component: FC<any>) => (child: ReactNode) =>

      process.env.NODE_ENV === 'production' || !component?.name ?

        (child as any)?.type === component :

        //react-standard alternative to apprise-level proxy container roles (since January 2023)
        ((child as any)?.type?.name === component?.name || (child as any)?.type?.displayName === component?.name)

    ,

    // accepts react nodes that are elements of types "annotated" with a given property, optionally with a given value.
    // it's a weaker form of isElementOf that can recognize elements of proxy components, such as those returned by useStable().
    // it's curried for convenience of use in iterations.
    isAnnotatedWith: (prop: string, value?: any) => (child: ReactNode) => {

      const type = (child as any)?.type ?? {}

      return value ? type[prop] === value : type.hasOwnProperty(prop)

    }

    ,

    //  accepts nodes that are either elements annotated with a role (cf. isAnnotatedWith), or have a key that starts with a role.
    //  it's curried for convenience of use in iterations.
    hasRole: (role: string) => (child: ReactNode) => self.isAnnotatedWith(role)(child) || (child as any)?.key?.startsWith(role)


  }


  return self;
}
