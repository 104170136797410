import { GenericRecord, RecordPatch, RecordSlot } from '#record/model';
import { Vid } from '#vid/model';
import { DeepPartial, utils } from 'apprise-frontend-core/utils/common';
import { TenantReference } from 'apprise-frontend-iam/tenant/model';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { carrierKindTag, detailsType, fishingKindTag, operatingCompanyTag } from './constants';

export type DetailsPatch = RecordPatch<typeof detailsType>


export type Details = RecordSlot & {


    /**
      *  additional vessel identifiers from the domain, both persistent or transient.
      **/
    identifiers: Vid[]


    /**
     *  the current state of the vessel.
     */
    // note: this is other than the state of the record, as available in its lifeycycle.
    state: VesselState


    /** the registered name.  */
    name: string

    /** 
     * the country where the vessel was registered and by whose rules it is primarily bound. 
     * */
    flagstate: TenantReference

    /** 
     * the port of the flagstate where the vessel is officially registered. 
     **/
    port: PortReference


    /**
     * The range in which the vessel operates.
     */
    range: TagReference


    /** 
     * the physical characteristics of the vessel. 
     **/
    specifications: Record<TagReference,any>


    /** the broad category of the vessel.  */
    vesselKind: TagReference


    /** the type of the vessel.  */
    vesselType: TagReference

    /**
     * the fishing equipment available on the vessel. 
     */
    gears: TagReference[]

    /**
     *  a set of role profiles associated with the management of the vessel.
     */
    contacts: Contact[]


    /**
     *  Free-form vessel properties from prior history.
     */
    priorHistory: {

        name: string
        flags: string
        deletions: string
    }

}

export type VesselState = 'active' | 'inactive'


export type Port = {

    name: string

}

export type PortReference = {
    
    name : string
    code?: string
    flag: TenantReference
}


export type Contact = {

    type: TagReference
    id?: string
    name: string
    address: string

}

export type OperatingCompany = Contact & {

    regno: string

}

export const isCarrierVessel = (r:GenericRecord | undefined ) => r?.details?.vesselKind === carrierKindTag
export const isFishingVessel = (r:GenericRecord | undefined ) => r?.details?.vesselKind === undefined || r?.details?.vesselKind === fishingKindTag

export const isOperatingCompany = (c: Contact): c is OperatingCompany => { return c.type === operatingCompanyTag}

// minimal 
export const newDetailSlot = () => {

    const partials : DeepPartial<Details> = {

        id: utils().mint('D'),
        identifiers:[],
        contacts:[],
        gears:[],
        specifications: {},
        port: {
            name: undefined
        }

    } 

    return partials as Details
}