import { RavConfig } from '#config';
import { useGenericPatchParser } from '#record/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { DeepPartial, utils } from 'apprise-frontend-core/utils/common';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useParseUtils } from 'apprise-frontend-parse/utils';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { delistingReasonCategory, delistingType } from './constants';
import { DelistingJsonSerialization } from './jsonserializer';
import { DelistingPatch } from './model';
 



export const useDelistingParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser = useDefaultModelParser(useDelistingRowParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, delistingType)

  return useDefaultParser({ modelparser, bookparser })

}


export const useDelistingRowParser = () => {

  const t = useT()
  const parse = useParseUtils()

  const parser = (row: DelistingJsonSerialization) => {

    const date = parse.date(row.date).orIssue('error').about(t('delisting.date')).on(row.uvi ?? t('rec.no_identifier'))

    const patch: DeepPartial<DelistingPatch> = {

      patchedSlots: [delistingType],

      [delistingType]: {

        timestamp: undefined!,

        id: utils().mint('DEL'),

        reason: parse.code2id(row.reason).of(delistingReasonCategory),
        date,
        note: row.note

      }

    }

    return patch as DelistingPatch
  
  }

  return useGenericPatchParser(parser)

}