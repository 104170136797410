import { useMocks } from 'apprise-frontend-core/client/mocks';
import { useService } from 'apprise-frontend-core/client/service';
import { useIntlMocks } from 'apprise-frontend-core/intl/mocks';
import { utils } from 'apprise-frontend-core/utils/common';
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle';
import { useUserMocks } from 'apprise-frontend-iam/user/mockery';
import { lorem, loremM } from 'apprise-ui/utils/constants';
import MockAdapter from 'axios-mock-adapter/types';
import { tagcategoryapi } from '../category/calls';
import { cardinalities, CategoryLifecycleState, TagCategory, useCategoryModel } from '../category/model';
import { contextCategory, systemModule, tagType } from '../constants';


export const useCategoryMockery = () => {


    const service = useService()
    const mocks = useCategoryMocks()
    const usermocks = useUserMocks()

    const updateCategoryLifecycle = (cat: TagCategory) => {
        cat.lifecycle.lastModified = Date.now()
        cat.lifecycle.lastModifiedBy = usermocks.logged().username

        return cat
    }

    return (mock: MockAdapter) => {

        console.log("mocking categories...")

        const svc = service.get(tagType)
        const api = `${svc.prefix}${tagcategoryapi}`
        const regapi = RegExp(`${api}/.+$`)

        const newCategoryId = () => utils().mint('TGC')

        const categories = mocks.categoryStore()

        mock.onGet(api).reply(() => [200, categories.all()])

        mock.onPost(api).reply(({ data }) => {

            const model = JSON.parse(data) as TagCategory

            model.id = newCategoryId()

            model.lifecycle.created = Date.now()

            return [200, categories.add(updateCategoryLifecycle(model))]
        })

        // don't need to use path parm, but real backend will check it matches model in body.     
        mock.onPut(api).reply(({ data }) => [200, categories.updateMany(...JSON.parse(data).map(updateCategoryLifecycle))])

        // don't need to use path parm, but real backend will check it matches model in body.     
        mock.onPut(regapi).reply(({ data }) => [200, categories.update(updateCategoryLifecycle(JSON.parse(data)))])

        mock.onDelete(regapi).reply(({ url }) => [204, categories.delete(url!.split('/').pop())])
    }



}



export const useCategoryMocks = () => {

    const mocks = useMocks()
    const { mockMultilang } = useIntlMocks()
    const { pastLifecycle } = useLifecycleMocks()

    const categories = useCategoryModel()

    const self = {


        categoryStore: () => mocks.getOrCreateStore<TagCategory>("tagcategories")

        ,

        mockCategories: () => ({

            context: {

                ...categories.newCategory(systemModule.type),
                id: contextCategory,

                predefined: true,
                lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

                cardinality: cardinalities.one,

                name: mockMultilang("Context"),
                description: mockMultilang("Context description"),

                properties: {
                    color: "#1976D2",
                    field: {
                        enabled: true,
                        message: mockMultilang("Context message"),
                        help: mockMultilang("Context help")
                    }
                }

            }
        })

        ,

        mockCategory: (seed: string, type: string = systemModule.type, cardinality = cardinalities.any): TagCategory => ({

            ...categories.newCategory(type),
            id: `TGC-${seed.toLowerCase().replace(' ', '-')}`,

            lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

            cardinality,

            name: mockMultilang(seed),
            description: mockMultilang(`${seed} description`),

            properties: {
                field: {
                    enabled: true,
                    message: mockMultilang(lorem),
                    help: mockMultilang(loremM)
                }
            }
        })

    }

    return self

}

